import {useState, useRef, useEffect} from 'react';
import API from '../util/AdminAPI';
import ClickableTable from '../components/ClickableTable';
import BillingTable from '../components/BillingTable';
import Modal from '../components/Modal';
import Button from '../components/Button';
import InvoiceDetail from '../components/InvoiceDetail';
import SelectInput from '../components/SelectInput';
import TextInput from '../components/TextInput';
import NewTextInput from '../components/NewCurrencyInput';
import CurrencyInput from '../components/CurrencyInput';
import DateInput from '../components/DateInput';
import SearchBox from '../components/SearchBox';


const BillingScreen = (props) => {
    const api: API = props.API;

    const [customers, setCustomers] = useState([]);
    const [customerInvoices, setCustomerInvoices] = useState([]);
    const [pending, setPending] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [herds, setHerds] = useState([]);
    const [processingCenters, setProcessingCenters] = useState([]);
    const [categories, setCategories] = useState([]);
    const [herdIndex, setHerdIndex] = useState(0);
    const [pcIndex, setPcIndex] = useState(0);
    const [categoryIndex, setCategoryIndex] = useState(0);
    const [pcFeeVisible, setPcFeeVisible] = useState(false);
    const [quickEntryVisible, setQuickEntryVisible] = useState(false);
    const [quickEntryVals, setQuickEntryVals] = useState([]);
    const [iifVisible, setIifVisible] = useState(false);
    const [lineItemVisible, setLineItemVisible] = useState(false);
    const [modalError, setModalError] = useState("");
    const [unImportedInvoices, setUnimportedInvoices] = useState([]);
    const [errors, setErrors] = useState([]);
    const [lineItemIndex, setLineItemIndex] = useState(0);
    const [editLineItemVisible, setEditLineItemVisible] = useState(false);

    const pcAmountRef = useRef(null);

    const rateRef = useRef(null);
    const quantityRef = useRef(null);
    const descriptionRef = useRef(null);
    const startInvoiceRef = useRef(null);
    const endInvoiceRef = useRef(null);
    const dateRef = useRef(null);

    const getPendingInvoices = async () => {
        setPending(await api.getPendingInvoices());
    }

    const getHerds = async () => {
        let herds = await api.getActiveHerds();
        let vals = [];

        for (let i=0; i<herds.length; i++) {
            vals.push("");
        }

        herds.sort((a, b) => {
            return a.usda_id - b.usda_id;
        });

        setHerds(herds)
        setQuickEntryVals(vals);
    }

    const getProcessingCenters = async () => {
        setProcessingCenters(await api.getProcessingCenters());
    }

    const getInvoiceLineItemCategoires = async () => {
        setCategories(await api.getInvoiceLineItemCategories());
    }

    const getUnimportedInvoices = async () => {
        setUnimportedInvoices(await api.getUnimportedInvoices());
    }

    const deleteLineItem = async () => {
        const id = pending[selectedIndex].line_items[lineItemIndex].id;
        await api.deleteInvoiceLineItem(id);
        await getPendingInvoices();

        hideModal();
    }

    const getCustomers = async () => {
        setCustomers(await api.getCustomers());
    }

    const getCustomerInvoices = async (cust_id) => {
        setCustomerInvoices(await api.getCustomerInvoices(cust_id));
    }

    const downloadInvoice = (index) => {
        const id = customerInvoices[index].id;
        api.downloadInvoice(id);
    }

    useEffect(() => {
        getPendingInvoices();
        getHerds();
        getProcessingCenters();
        getInvoiceLineItemCategoires();
        getUnimportedInvoices();
        getCustomers();
    }, []);

    const getTableData = () => {
        return pending.map((item, index) => {
            const cust = item.cust.name;
            const items_length = item.line_items.length;
            const amount = "$ " + item.total.toLocaleString();
            const held = item.hold;

            return [index, cust, items_length, amount, held]
        });
    }

    const getCustomerTableData = () => {
        return customerInvoices.map((item, index) => {
            const date = item.published;
            const items_length = item.line_items.length;
            const amount = "$ " + item.total.toLocaleString();

            return [index, date, items_length, amount]
        });
    }

    const hideModal = () => {
        setPcFeeVisible(false);
        setLineItemVisible(false);
        setIifVisible(false);
        setQuickEntryVisible(false);
        setEditLineItemVisible(false);

        let vals = quickEntryVals.slice();
        vals = vals.map((_) => "");

        setQuickEntryVals(vals);

        pcAmountRef.current.value = "";
        rateRef.current.value = "";
        quantityRef.current.value = "";
        descriptionRef.current.value = "";
        setCategoryIndex(0);
    }

    const newProcessingCenterFee = async () => {
        if (validateProcessingCenterFee()) {
            const amount = pcAmountRef.current.value;
            const herd_id = herds[herdIndex].id;
            const pc_id = processingCenters[pcIndex].id;

            await api.newProcessingCenterFee(herd_id, pc_id, amount);
            await getPendingInvoices();
            hideModal();
        }
    }

    const validateProcessingCenterFee = () => {
        if (pcAmountRef.current.value === "") {
            setModalError("Amount cannot be empty");
            return false;
        }
        return true;
    }

    const newLineItem = async () => {
        const rate = rateRef.current.value;
        const quantity = quantityRef.current.value;
        const description = descriptionRef.current.value;
        const categoryId = categories[categoryIndex].id;
        const herdId = pending[selectedIndex].herd.id;

        if (validateLineItem(rate, quantity, description)) {
            await api.newInvoiceLineItem(herdId, categoryId, rate, quantity, description);
            await getPendingInvoices();
            hideModal();
        }
    }

    const editLineItem = async () => {
        const id = pending[selectedIndex].line_items[lineItemIndex].id;
        const rate = rateRef.current.value;
        const quantity = quantityRef.current.value;
        const description = descriptionRef.current.value;
        const supplyDate = dateRef.current.value;

        const data = {
            rate: rate,
            quantity: quantity,
            description: description,
            supply_date: supplyDate + " 12:00:00.000000"

        };

        if (validateLineItem(rate, quantity, description)) {
            await api.editInvoiceLineItem(id, data);
            await getPendingInvoices();
            hideModal();
        }
    }

    const downloadIIF = async () => {
        const start = startInvoiceRef.current.value;
        const end = endInvoiceRef.current.value;

        if (validateIIFDownload(start, end)) {
            await api.getIIFRange(start, end);
            await getUnimportedInvoices();
            hideModal();
        }
    }

    const validateIIFDownload = (start, end) => {
        if (start > end) {
            setModalError("Start cannot be greater than end");
            return false;
        } else if (start === "") {
            setModalError("First Invoice Number cannot be empty");
            return false;
        } else if (end === "") {
            setModalError("Last Invoice Number cannot be empty");
            return false;
        }

        return true;
    }

    const showIIFModal = () => {
        setIifVisible(true);
        if (unImportedInvoices.length > 0) {
            let nums = unImportedInvoices.map(i => i.invoice_num);
            startInvoiceRef.current.value = Math.min(nums);
            endInvoiceRef.current.value = Math.max(nums);
        }
    }

    const showEditLineItemModal = (id) => {
        const index = pending[selectedIndex].line_items.findIndex((a) => a.id===id);
        const line_item = pending[selectedIndex].line_items[index];
        rateRef.current.value = line_item.rate;
        quantityRef.current.value = line_item.quantity;
        descriptionRef.current.value = line_item.description;
        const date = new Date(line_item.supply_date);
        const s = date.getFullYear() + "-" + String(date.getMonth()+1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0');
        dateRef.current.value = s
        setLineItemIndex(index);
        setEditLineItemVisible(true);
    }

    const validateLineItem = (rate, quantity, description) => {
        if (rate === "") {
            setModalError("Rate cannot be empty");
            return false;
        } else if (quantity === "") {
            setModalError("Quantity cannot be empty");
            return false;
        } else if (description === "") {
            setModalError("Description cannot be empty");
            return false;
        }

        return true;
    }

    const sendInvoices = async () => {
        setTimeout(() => {
            getPendingInvoices();
            getUnimportedInvoices();
        }, 3000)
        await api.sendPendingInvoices();
    }

    const sendInvoice = async () => {
        setTimeout(() => {
            setSelectedIndex(-1);
            getPendingInvoices();
            getUnimportedInvoices();
        }, 3000);
        const id = pending[selectedIndex].id;
        await api.sendPendingInvoice(id);
    }

    const onQuickEntryChange = (index, val) => {
        let vals = quickEntryVals.slice();
        vals[index] = val.target.value;

        setQuickEntryVals(vals);
    }

    const quickEntryUpload = async () => {
        let quickHerds = herds.filter((herd) => {
            if (!herd.processing_center) {
                return false;
            }

            if (!herd.is_official) {
                return false;
            }

            return herd.processing_center.id === processingCenters[pcIndex].id;
        });


        for (let i=0; i<quickHerds.length; i++) {
            const herd = quickHerds[i];
            const val = quickEntryVals[i];

            if (val !== "") {
                await api.newProcessingCenterFee(herd.id, herd.processing_center.id, val);
            }
        }

        await getPendingInvoices();
        hideModal();
    }

    const setHeld = async (index, val) => {
        if (pending.length === 0) {
            return;
        }
        const id = pending[index].id;
        const invoice = await api.updateInvoice(id, val);

        const pend = pending.slice();
        pend[index] = invoice;
        setPending(pend);
    }

    let quickEntrySum = 0;
    quickEntryVals.forEach((i) => {
        if (isNaN(i)) { return; }
        quickEntrySum += Number(i);
    });

    return (
        <div className="BillingScreen">
            <div>
                <Button text="Send Ready Invoices" onClick={sendInvoices}/>
                <div style={{height: 16}} />
                <Button type="text" text="Add Processing Center Fee (single)" onClick={() => {setPcFeeVisible(true)}}/>
                <div style={{height: 8}} />
                <Button type="text" text="Add Processing Center Fee (quick entry)" onClick={() => {setQuickEntryVisible(true)}} />
                <div style={{height: 8}} />
                <Button type="text" text="Download IIF File" onClick={showIIFModal} />
            </div>
            <div style={{height: 32}} />
            <div>
            {
                errors.map((err) => <p className="error">{err}</p>)
            }
            </div>
            {
                selectedIndex < 0 || pending.length === 0? <div />:
                <InvoiceDetail
                    invoice={pending[selectedIndex]}
                    editItem={showEditLineItemModal}
                    newLineItem={() => {setLineItemVisible(true);}}
                    sendInvoice={sendInvoice}
                />
            }

            <p>Pending Invoices</p>
            <BillingTable
                headers={["Customer", "# Items", "Amount"]}
                data={getTableData()}
                onClick={setSelectedIndex}
                setHeld={setHeld}
            />
            <div style={{height: "40px"}} />
            <p>Past Invoices</p>
            <SearchBox text="Search Customers" width="50%" options={customers.map(cust => cust.old_id + " - " + cust.name)} onSelect={(index) => {
                if (index !== -1) {
                    getCustomerInvoices(customers[index].id)
                } else {
                    setCustomerInvoices([]);
                }
            }} />
            <ClickableTable
                headers={["Date", "# Items", "Amount"]}
                data={getCustomerTableData()}
                onClick={downloadInvoice}
            />
            <Modal
                title="Add Processing Center Fee"
                visible={pcFeeVisible}
                primaryText="Save"
                onPrimary={newProcessingCenterFee}
                secondaryText="Cancel"
                onSecondary={hideModal}
                error={modalError}
            >
                <SelectInput
                    placeholder="Herd"
                    options={herds}
                    onSelect={setHerdIndex}
                    selectedIndex={herdIndex}
                />
                <SelectInput
                    placeholder="Processing Center"
                    options={processingCenters}
                    onSelect={setPcIndex}
                    selectedIndex={pcIndex}
                />
                <CurrencyInput
                    innerRef={pcAmountRef}
                    placeholder="Amount"
                />
            </Modal>
            <Modal
                title="Processing Center Fee Quick Entry"
                visible={quickEntryVisible}
                primaryText="Save"
                onPrimary={quickEntryUpload}
                secondaryText="Cancel"
                onSecondary={hideModal}
            >
                <SelectInput
                    placeholder="Processing Center"
                    options={processingCenters}
                    onSelect={setPcIndex}
                    selectedIndex={pcIndex}
                />
                <div style={{width: '500px'}}>
                {
                    herds
                    .filter((herd) => {
                        if (!processingCenters[pcIndex]) {
                            return false;
                        }

                        if (!herd.processing_center) {
                            return false;
                        }

                        if (!herd.is_official) {
                            return false;
                        }

                        return herd.processing_center.id === processingCenters[pcIndex].id;
                    })
                    .map((herd, index) => {
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <p style={{alignSelf: 'flex-start', fontSize: 14, color: '#444'}}>{herd.usda_id} - {herd.name}</p>
                                <NewTextInput placeholder="Amount" value={quickEntryVals[index]} onChange={(val) => {onQuickEntryChange(index, val)}}/>
                            </div>
                        );
                    })
                }
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div>Total:</div>
                        <p>{quickEntrySum.toFixed(2)}</p>
                    </div>
                </div>
            </Modal>
            <Modal
                title="Add Line Item"
                visible={lineItemVisible}
                primaryText="Save"
                onPrimary={newLineItem}
                secondaryText="Cancel"
                onSecondary={hideModal}
                error={modalError}
            >
                <SelectInput 
                    placeholder="Category"
                    options={categories}
                    selectedIndex={categoryIndex}
                    onSelect={setCategoryIndex}
                />
                <CurrencyInput placeholder="Rate" innerRef={rateRef}  />
                <TextInput placeholder="Quantity" innerRef={quantityRef} type="number" />
                <TextInput placeholder="Description" innerRef={descriptionRef} />
            </Modal>
            <Modal
                title="Download IIF File"
                visible={iifVisible}
                primaryText="Download"
                onPrimary={downloadIIF}
                secondaryText="Cancel"
                onSecondary={hideModal}
                error={modalError}
            >
                <TextInput type="number" placeholder="First Invoice Number" innerRef={startInvoiceRef} />
                <TextInput type="number" placeholder="Last Invoice Number" innerRef={endInvoiceRef} />
            </Modal>
            <Modal
                title="Edit Line Item"
                visible={editLineItemVisible}
                primaryText="Save"
                onPrimary={editLineItem}
                secondaryText="Cancel"
                onSecondary={hideModal}
                ternaryText="Delete"
                onTernary={deleteLineItem}
            >
                <CurrencyInput placeholder="Rate" innerRef={rateRef}  />
                <TextInput placeholder="Quantity" innerRef={quantityRef} type="number" />
                <TextInput placeholder="Description" innerRef={descriptionRef} />
                <DateInput placeholder="Supply Date" innerRef={dateRef} />
            </Modal>
        </div>
    )
}

export default BillingScreen;
