import './styles/App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useEffect, useState, useRef, useMemo } from 'react';
import AdminNav from './components/AdminNav';
import CustomerNav from './components/CustomerNav';
import AdminAPI from './util/AdminAPI';
import CustomerAPI from './util/CustAPI';

import LandingScreen from './screens/Landing';

import AdminLoginScreen from './screens/AdminLogin';
import CustomerScreen from './screens/customers';
import DataEntryScreen from './screens/dataentry';
import AdminSettingsScreen from './screens/AdminSettings';
import BillingScreen from './screens/Billing';
import DryMatterScreen from './screens/DryMatter';
import AdminReports from './screens/AdminReports.jsx';

import CustomerLoginScreen from './screens/CustomerLogin';
import ResultScreen from './screens/ResultScreen';
import CustomerContacts from './screens/CustomerContacts';
import RequestResetScreen from './screens/RequestReset';
import PasswordResetScreen from './screens/PasswordReset';
import CustomerReports from './screens/CustomerReports';
import AdminAdminScreen from './screens/AdminAdmin';
import AnalyticsScreen from './screens/CustomerAnalytics';
import PrivacyPolicyScreen from './screens/PrivacyPolicy';
import EmailFormScreen from './screens/EmailForm';
import AccountRequestScreen from './screens/RequestAccount';


const App = () => {
    return (
        <Router>
        <Switch>
            <Route path="/" exact>
                <LandingScreen />
            </Route>
            <Route path="/privacy" exact>
                <PrivacyPolicyScreen />
            </Route>
            <Route path="/admin">
                <AdminApp/>
            </Route>
            <Route path="/cloud">
                <CustomerApp />
            </Route>
        </Switch>
        </Router>
    );
}

const AdminApp = (props) => {
    const api = useMemo(() => new AdminAPI(), []);
    const [loading, setLoading] = useState(true);

    const scrollRef = useRef(null);

    useEffect(() => {
        const load = async () => {
            await api.checkLogin();
            setLoading(false);
        }

        load();
    }, [api])

    const [dryMatter, setDryMatter] = useState([["", "", "", "", 0, "", "", "", "", "", ""]]);
    const [dmCust, setDmCust] = useState(-1);

    return (
        <div>
        {loading? <div>Loading</div>:
        <Switch>
            <Route path="/admin/login">
                <AdminLoginScreen API={api}/>
            </Route>
            <AdminNav scrollRef={scrollRef}>
                <Route path="/admin/" exact>
                    <CustomerScreen API={api} scrollRef={scrollRef}/>
                </Route>
                <Route path="/admin/dataentry/:herdId" render={(props) => {
                    return <DataEntryScreen API={api} scrollRef={scrollRef} herdId={props.match.params.herdId} />;
                }} />
                <Route path="/admin/dataentry" exact>
                    <DataEntryScreen API={api} scrollRef={scrollRef}/>
                </Route>
                <Route path="/admin/drymatter" exact>
                    <DryMatterScreen API={api} dryMatter={dryMatter} setDryMatter={setDryMatter} dmCust={dmCust} setDmCust={setDmCust}/>
                </Route>
                <Route path="/admin/billing" exact>
                    <BillingScreen API={api} />
                </Route>
                <Route path="/admin/settings" exact>
                    <AdminSettingsScreen API={api} />
                </Route>
                <Route path="/admin/admin" exact>
                    <AdminAdminScreen API={api} />
                </Route>
                <Route path="/admin/reports/:herdId" render = {(props) => {
                    return <AdminReports API={api} herdId={props.match.params.herdId} />;
                }} />
                <Route path="/admin/reports" exact>
                    <AdminReports API={api}/>
                </Route>
            </AdminNav>
        </Switch>
        }
        </div>
    )
}

const CustomerApp = (props) => {
    const api = useMemo(() => new CustomerAPI(), []);
    const [loading, setLoading] = useState(true);

    const scrollRef = useRef(null);

    useEffect(() => {
        const load = async () => {
            await api.checkLogin();
            setLoading(false);
        }

        load();
    }, [api])

    return (
        <div>
        {loading? <div>Loading...</div>:
        <Switch>
            <Route path="/cloud/login">
                <CustomerLoginScreen API={api} />
            </Route>
            <Route path="/cloud/request_reset">
                <RequestResetScreen API={api}/>
            </Route>
            <Route path="/cloud/request_account">
                <AccountRequestScreen API={api}/>
            </Route>
            <Route path="/cloud/reset/:id" render={(props) => {
                return <PasswordResetScreen API={api} {...props} />;
            }} />
            <Route path="/cloud/email_form">
                <EmailFormScreen API={api} />
            </Route>
            <CustomerNav API={api} scrollRef={scrollRef}>
                <Route path="/cloud" exact>
                    <ResultScreen API={api} />
                </Route>
                <Route path="/cloud/contacts">
                    <CustomerContacts API={api} />
                </Route>
                <Route path="/cloud/reports">
                    <CustomerReports API={api} />
                </Route>
                <Route path="/cloud/analytics">
                    <AnalyticsScreen API={api} />
                </Route>
            </CustomerNav>
        </Switch>
        }
        </div>
    );
}


export default App;
