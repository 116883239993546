import '../styles/Nav.scss';
import logo from '../assets/logo.svg';
import Button from './Button';
import { NavLink } from 'react-router-dom';

const Nav = (props) => {

    const scrollRef = props.scrollRef;

    const logout = () => {
        localStorage.clear();
        window.location.href = "/admin/login";
    }

    return (
        <div className="nav-container">
            <div className="nav-logo">
                <img src={logo} alt="Logo" width={68} height={50}/>
                <p>High Desert Dairy Lab</p>
            </div>
            <div className="nav-header">
                <Button text="Logout" onClick={logout}/>
            </div>
            <div className="nav-bar">
                <NavLink to="/admin/" activeClassName="selected" exact>Customers</NavLink>
                <NavLink to="/admin/dataentry" activeClassName="selected">Data Entry</NavLink>
                <NavLink to="/admin/drymatter" activeClassName="selected">Dry Matter</NavLink>
                <NavLink to="/admin/billing" activeClassName="selected" exact>Billing</NavLink>
                <NavLink to="/admin/reports" activeClassName="selected">Reports</NavLink>
                <NavLink to="/admin/admin" activeClassName="selected" exact>Admin</NavLink>
                <NavLink to="/admin/settings" activeClassName="selected" exact>Settings</NavLink>

            </div>
            <div className="nav-content" ref={scrollRef}>
                {props.children}
            </div>
        </div>
    );
}


export default Nav;
