import {Redirect} from 'react-router-dom';

const base_url = process.env.NODE_ENV === "development"? "http://localhost:5000/api": `${window.location.origin}/api`;

export default class AdminAPI {

    constructor() {
        this.loggedIn = false;
        this.listeners = [];

        // Check if user has valid token
        this.readToken();
    }

    addListener = (func) => {
        this.listeners.push(func);
    }

    removeListener = (func) => {
        this.listeners = this.listeners.filter(val => val !== func);
    }

    alert = (msg) => {
        this.listeners.forEach((func) => {func(msg);});
    }

    addToken = (urlString, params={}) => {
        params.token = this.token
        return urlString + "?" + new URLSearchParams(params);
    }

    fetch = async (url, options) => {
        const r = await fetch(url, options);
        if (r.status === 403) {
            this.loggedIn = false;
        }

        return r;
    }

    login = async (email, password) => {
        const r = await this.fetch(`${base_url}/auth/admin`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        });

        if (r.status === 200) {
            const json = await r.json();
            this.token = json.token;
            this.writeToken();
            this.loggedIn = true;
            this.alert("Successfully logged in");
        } else {
            this.alert("Invalid Username/Password");
        }
    }

    checkLogin = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/auth/admin`));

        this.loggedIn = r.status === 200;
        await r.json();
    }

    readToken = () => {
        this.token = localStorage.getItem("admin_token");
    }

    writeToken = () => {
        localStorage.setItem("admin_token", this.token);
    }

    getCustomers = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/customers/`));
        return await r.json();
    }

    getCustomersAllContacts = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/customers/allcontacts`));
        return await r.json();
    }

    getDryMatterCustomers = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/drymatter/customers/`));
        return await r.json();
    }

    getFeedTypes = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/drymatter/feed_types`));
        return await r.json();
    }

    getCustomer = async (id) => {
        const r = await this.fetch(this.addToken(`${base_url}/customers/${id}`));
        return await r.json();
    }

    getCustomerInvoices = async (cust_id) => {
        const r = await this.fetch(this.addToken(`${base_url}/invoices/${cust_id}`));
        return await r.json();
    }

    downloadInvoice = (id) => {
        const url = this.addToken(`${base_url}/invoices/download/${id}`);
        window.open(url, '_blank');
    }

    getAssociationReport = (year, month) => {
        const url = this.addToken(`${base_url}/reports/association_membership/${year}/${month}`);
        window.open(url, '_blank');
    }

    newCustomer = async (
        name,
        email,
        billing_rate,
        addr1,
        addr2,
        city,
        state,
        zipcode,
        mobile_num
    ) => {
        const data = {
            name: name,
            email: email,
            billing_rate: billing_rate,
            addr1: addr1,
            addr2: addr2,
            city: city,
            state: state,
            zipcode: zipcode,
            mobile_num: mobile_num,
            token: this.token
        };

        const r = await this.fetch(`${base_url}/customers/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        const json = await r.json();
        return json
    }

    updateCustomer = async (id, data) => {
        await this.fetch(this.addToken(`${base_url}/customers/${id}`), {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    deleteCustomer = async (id) => {
        await this.fetch(this.addToken(`${base_url}/customers/${id}`), {
            method: 'DELETE'
        });
    }

    getContact = async (id) => {
        const r = await this.fetch(this.addToken(`${base_url}/contacts/${id}`));
        return await r.json();
    }

    updateContact = async (id, data) => {
        const d = data;
        d.token = this.token;
        await this.fetch(`${base_url}/contacts/${id}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(d)
        });
    }

    getHerds = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/herds/`));
        const json = await r.json();
        return json;
    }

    getActiveHerds = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/herds/active`));
        const json = await r.json();
        return json;
    }

    getHerd = async (id) => {
        const r = await this.fetch(this.addToken(`${base_url}/herds/${id}`));
        const json = await r.json();
        return json;
    }

    newHerd = async (cust_id, name, regionID, breedID, processingID, isAM, isOfficial, usdaId, milkings, comments, weight_factor, prompt_tank_weights, dhia_charge, billingAddr, billingAddr2, billingCity, billingState, billingZipcode, filecode) => {
        const data = {
            cust_id: cust_id,
            name: name,
            region_id: regionID,
            breed_id: breedID,
            processing_center_id: processingID,
            is_association_member: isAM,
            is_official: isOfficial,
            usdaId: isOfficial? usdaId: null,
            milkings_per_day: milkings,
            comments: comments,
            weight_factor: weight_factor,
            prompt_tank_weights: prompt_tank_weights,
            dhia_charge: dhia_charge,
            addr: billingAddr,
            addr2: billingAddr2,
            city: billingCity,
            state: billingState,
            zipcode: billingZipcode,
            filecode: filecode,
            token: this.token
        }


        await this.fetch(`${base_url}/herds/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        });
    }

    updateHerd = async (id, data) => {
        data.token = this.token;
        await this.fetch(`${base_url}/herds/${id}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    deleteHerd = async (id) => {
        await this.fetch(this.addToken(`${base_url}/herds/${id}`), {
            method: 'DELETE',
        });
    }

    getRegions = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/regions/`));
        return await r.json();
    }

    newRegion = async (name) => {
        await this.fetch(`${base_url}/regions/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                token: this.token
            })
        });
    }

    updateRegion = async (id, name) => {
        await this.fetch(`${base_url}/regions/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                token: this.token
            })
        });
    }

    deleteRegion = async (id) =>{
        await this.fetch(this.addToken(`${base_url}/regions/${id}`), {
            method: "DELETE"
        });
    }

    getBreeds = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/breeds/`));
        const json = await r.json();
        return json;
    }

    newBreed = async (name) => {
        await this.fetch(`${base_url}/breeds/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                token: this.token
            })
        });
    }

    updateBreed = async (id, name) => {
        await this.fetch(`${base_url}/breeds/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                token: this.token
            })
        });
    }

    deleteBreed = async (id) =>{
        await this.fetch(this.addToken(`${base_url}/breeds/${id}`), {
            method: "DELETE"
        });
    }

    newFeedType = async (name) => {
        await this.fetch(`${base_url}/drymatter/feed_types`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                token: this.token
            })
        });
    }

    updateFeedType = async (id, name) => {
        await this.fetch(`${base_url}/drymatter/feed_types/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                token: this.token
            })
        });
    }

    deleteFeedType = async (id) =>{
        await this.fetch(this.addToken(`${base_url}/drymatter/feed_types/${id}`), {
            method: "DELETE"
        });
    }

    getProcessingCenters = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/processing_centers/`));
        const json = await r.json();
        return json;
    }

    uploadDrymatterSamples = async (cust_id, samples) => {
        const body = JSON.stringify({
            cust_id: cust_id,
            samples: samples,
            token: this.token
        });

        const r = await this.fetch(`${base_url}/drymatter/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: body,
        });

        const json = await r.json();
        return json;
    }

    verifyDrymatterSamples = async (batch_id) => {
        const body = JSON.stringify({
            batch_id: batch_id,
            token: this.token
        });

        await this.fetch(`${base_url}/drymatter/verify`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: body,
        });
    }

    newProcessingCenter = async (
        name,
        email,
        mobile_num,
        addr1,
        addr2,
        city,
        state,
        zipcode
    ) => {
        await this.fetch(`${base_url}/processing_centers/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                email: email,
                mobile_num: mobile_num,
                addr1: addr1,
                addr2: addr2,
                city: city,
                state: state,
                zipcode: zipcode,
                token: this.token
            })
        });
    }

    updateProcessingCenters = async (id, data) => {
        data.token = this.token;
        await this.fetch(`${base_url}/processing_centers/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    deleteProcessingCenters = async (id) =>{
        await this.fetch(this.addToken(`${base_url}/processing_centers/${id}`), {
            method: "DELETE"
        });
    }

    getRecentResults = async (num) => {
        const r = await this.fetch(this.addToken(`${base_url}/results/latest/${num}`));
        const json = await r.json();
        return json;
    }

    uploadResult = async (herd_id, files, manual, force) => {
        const data = new FormData();
        data.append("token", this.token);
        data.append("herd_id", herd_id);
        data.append("manual", manual);
        data.append("force", force);
    
        files.rpt.forEach((f) => {
            data.append('rpt_files', f);
        });

        files.out.forEach((f) => {
            data.append('out_files', f);
        });

        const res = await this.fetch(`${base_url}/results/`, {
            method: "POST",
            body: data
        });
        const json = await res.json();
        return json;
    }

    uploadResultTank = async (herd_id, files, manual, tank_weight, force) => {
        const data = new FormData();
        data.append("token", this.token);
        data.append("herd_id", herd_id);
        data.append("manual", manual);
        data.append("tank_weight", tank_weight);
        data.append("force", force);
    
        files.rpt.forEach((f) => {
            data.append('rpt_files', f);
        });

        files.out.forEach((f) => {
            data.append('out_files', f);
        });

        const res = await this.fetch(`${base_url}/results/`, {
            method: "POST",
            body: data
        });
        const json = await res.json();
        return json;
    }

    verifyData = async (id, filename, message) => {
        await this.fetch(this.addToken(`${base_url}/results/verify/${id}`, {filename: filename, message: message}));
    }

    sendPCData = async (herd_id, sampleDate, receivedDate, shifts, pickups, notes) => {
        const data = {
            herd_id: herd_id,
            sample_date: sampleDate,
            received_date: receivedDate,
            shifts: shifts,
            pickups: pickups,
            notes: notes,
            token: this.token
        }

        await this.fetch(`${base_url}/results/pc`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    deleteResult = async (id) => {
        await this.fetch(this.addToken(`${base_url}/results/${id}`), {
            method: "DELETE"
        });
    }

    getAdminSettings = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/admin/settings`));
        return await r.json();
    }

    updateSetting = async (key, value) => {
        await this.fetch(`${base_url}/admin/settings/${key}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                value: value,
                token: this.token
            })
        })
    }

    downloadRPT = async (id) => {
        const url = this.addToken(`${base_url}/results/${id}/rpt`);
        window.open(url, '_blank');
    }

    downloadOUT = async (id) => {
        const url = this.addToken(`${base_url}/results/${id}/out`);
        window.open(url, '_blank');
    }

    downloadDTA = async (id) => {
        const url = this.addToken(`${base_url}/results/${id}/dta`);
        window.open(url, '_blank');
    }

    getPendingInvoices = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/invoices/pending`));
        return await r.json();
    }

    updateInvoice = async (id, hold) => {
        const data = {
            hold: hold,
            token: this.token
        }

        const res = await this.fetch(`${base_url}/invoices/invoice/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        return await res.json();
    }

    newInvoiceLineItem = async (herdId, categoryId, rate, quantity, description) => {
        const data = {
            category_id: categoryId,
            rate: rate,
            quantity: quantity,
            description: description,
            token: this.token
        }

        await this.fetch(`${base_url}/invoices/${herdId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    editInvoiceLineItem = async (id, data) => {
        data.token = this.token
        await this.fetch(`${base_url}/invoices/line_item/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    deleteInvoiceLineItem = async (id) => {
        await this.fetch(this.addToken(`${base_url}/invoices/line_item/${id}`), {
            method: "DELETE"
        });
    }

    getInvoiceLineItemCategories = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/invoices/line_item_category`));
        return await r.json();
    }

    getUnimportedInvoices = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/invoices/unimported`));
        return await r.json();
    }

    newInvoiceLineItemCategory = async (name, description) => {
        const data = {
            name: name,
            description: description,
            token: this.token
        };

        await this.fetch(`${base_url}/invoices/line_item_category`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    sendPendingInvoices = async () => {
        const url =  this.addToken(`${base_url}/invoices/send`);
        window.open(url, '_blank');
    }

    sendPendingInvoice = async (id) => {
        const url = this.addToken(`${base_url}/invoices/send/${id}`);
        window.open(url, '_blank');
    }

    getIIFRange = async (start, end) => {
        const url = this.addToken(`${base_url}/invoices/iif/${start}:${end}`);
        window.open(url, '_blank');
    }

    updateInvoiceLineItemCategory = async (id, name, desc) => {
        const data = {
            name: name,
            description: desc,
            token: this.token
        }

        await this.fetch(`${base_url}/invoices/line_item_category/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    deleteInvoiceLineItemCategory = async (id) => {
        await this.fetch(this.addToken(`${base_url}/invoices/line_item_category/${id}`), {
            method: "DELETE"
        });
    }

    newRecurringLineItem = async (custId, categoryId, amount) => {
        const data = {
            cust_id: custId,
            category_id: categoryId,
            amount: amount,
            token: this.token
        }

        await this.fetch(`${base_url}/invoices/recurring`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    updateRecurringLineitem = async (id, categoryId, amount) => {
        const data = {
            invoice_line_item_category_id: categoryId,
            rate: amount,
            token: this.token
        }

        await this.fetch(`${base_url}/invoices/recurring/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    deleteRecurringLineItem = async (id) => {
        await this.fetch(this.addToken(`${base_url}/invoices/recurring/${id}`), {
            method: "DELETE"
        });
    }

    newProcessingCenterFee = async (herd_id, pc_id, amount) => {
        const data = {
            herd_id: herd_id,
            processing_center_id: pc_id,
            amount: amount,
            token: this.token
        };

       await this.fetch(`${base_url}/invoices/line_item/processing_center`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

    }

    sendEmail = async (subject, body) => {
        const data = {
            subject: subject,
            body: body,
            token: this.token
        }

        await this.fetch(`${base_url}/admin/send`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    getHours = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/hours/`));
        return await r.json();
    }

    addHours = async (hours, day, month, year) => {
        const data = {
            hours: hours,
            day: day,
            month: month,
            year: year,
            token: this.token
        }

        await this.fetch(`${base_url}/hours/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    getHerdAverages = async (herd_id) => {
        const r = await this.fetch(this.addToken(`${base_url}/analysis/average_herd/${herd_id}`));
        return await r.json();
    }

    getRecentHerdResults = async (herd_id) => {
        const r = await this.fetch(this.addToken(`${base_url}/results/latest/3/herd/${herd_id}`))
        return await r.json();
    }

    getHerdContacts = async (id) => {
        const r = await this.fetch(this.addToken(`${base_url}/contacts/herd/${id}`));
        const json = await r.json();
        return json;
    }

    getCustomerContacts = async (id) => {
        const r = await this.fetch(this.addToken(`${base_url}/contacts/customer/${id}`));
        const json = await r.json();
        return json;
    }

    newCustomerContact = async (id, name, email, mobile_num, addr1, addr2, city, state, zipcode) => {
        const data = {
            name: name,
            email: email,
            mobile_num: mobile_num,
            addr1: addr1,
            addr2: addr2,
            city: city,
            state: state,
            zipcode: zipcode,
            token: this.token
        }

        await this.fetch(`${base_url}/contacts/customer/${id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    newHerdContact = async (herd_id, name, email, mobile_num, addr1, addr2, city, state, zipcode) => {
        const data = {
            name: name,
            email: email,
            mobile_num: mobile_num,
            addr1: addr1,
            addr2: addr2,
            city: city,
            state: state,
            zipcode: zipcode,
            token: this.token
        }

        await this.fetch(`${base_url}/contacts/herd/${herd_id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    updateContact = async (id, data) => {
        const d = data;
        d.token = this.token;
        await this.fetch(`${base_url}/contacts/${id}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(d)
        });
    }

    deleteCustomerContact = async (id, cust_id) => {
        await this.fetch(this.addToken(`${base_url}/contacts/${id}/customer/${cust_id}`), {
            method: "DELETE"
        });
    }

    deleteHerdContact = async (id, herd_id) => {
        await this.fetch(this.addToken(`${base_url}/contacts/${id}/herd/${herd_id}`), {
            method: "DELETE"
        });
    }

    getAdminAnalytics = async (groupby) => {
        const d = {
            token: this.token,
            groupby: groupby
        };

        const res = await this.fetch(`${base_url}/analysis/admin`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(d)
        });
        return await res.json();
    }

    getTrays = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/drymatter/trays`));
        return await r.json();
    }

    updateTray = async (id, weight) => {
        await this.fetch(`${base_url}/drymatter/trays/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                weight: weight,
                token: this.token
            })
        });
    }

    newTray = async (id, weight) => {
        await this.fetch(`${base_url}/drymatter/trays`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: id,
                weight: weight,
                token: this.token
            })
        });
    }

    deleteTray = async (id) => {
        await this.fetch(this.addToken(`${base_url}/drymatter/trays/${id}`), {
            method: "DELETE"
        });

    }

    getMunRate = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/results/get_mun_rate`));
        return await r.json();
    }

        // Reports
    getFavoriteReports = async (herdId) => {
        await this.download(`${base_url}/reports/favorites/${herdId}`);
    }

    getStandardReport = async (herdId) => {
        await this.download(`${base_url}/reports/standard/${herdId}`);
    }

    getSummaryReport = async (herdId) => {
        await this.download(`${base_url}/reports/summary/${herdId}`);
    }

    getSummaryMUNReport = async (herdId) => {
        await this.download(`${base_url}/reports/summary_mun/${herdId}`);
    }

    getEnergyCorrectedReport = async (herdId) => {
        await this.download(`${base_url}/reports/energy_corrected_milkweights/${herdId}`);
    }

    getFatCorrectedReport = async (herdId) => {
        await this.download(`${base_url}/reports/fat_corrected_milkweights/${herdId}`);
    }

    getLowMilkweightReport = async (herdId) => {
        await this.download(`${base_url}/reports/low_milkweight/${herdId}`);
    }

    getLowMilkweightPenReport = async (herdId) => {
        await this.download(`${base_url}/reports/low_milkweight_by_pen/${herdId}`);
    }

    getByMilkweightReport = async (herdId) => {
        await this.download(`${base_url}/reports/by_milkweight/${herdId}`);
    }

    getByPenReport = async (herdId) => {
        await this.download(`${base_url}/reports/by_pen/${herdId}`);
    }

    getByPenMilkweightReport = async (herdId) => {
        await this.download(`${base_url}/reports/by_pen_milkweight/${herdId}`);
    }

    getBySCCReport =async (herdId) => {
        await this.download(`${base_url}/reports/by_scc/${herdId}`);
    }

    getSCCContributionReport = async (herdId) => {
        await this.download(`${base_url}/reports/scc_contribution/${herdId}`);
    }

    getSCCOver500Report = async (herdId) => {
        await this.download(`${base_url}/reports/scc_over_500/${herdId}`);
    }

    getAverageResults = async () => {
        const r = await this.fetch(this.addToken(`${base_url}/analysis/average`));
        return await r.json();
    }

    getAllAverageResults = async (herdId, sameBreed, sameRegion, sameMilkings) => {
        const data = {
            herd_id: herdId,
            same_breed: sameBreed,
            same_region: sameRegion,
            same_milkings: sameMilkings,
            token: this.token
        }
        const r = await this.fetch(`${base_url}/analysis/average/all`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)

        });
        return await r.json();
    }

    getPercentiles = async (herd_id, sameBreed, sameRegion, sameMilkings) => {
        const data = {
            same_breed: sameBreed,
            same_region: sameRegion,
            same_milkings: sameMilkings,
            token: this.token
        }

        const r = await this.fetch(`${base_url}/analysis/percentiles/${herd_id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await r.json();
    }

    download = async (url) => {
        const new_url = this.addToken(url);
        window.open(new_url, '_blank');
    }


    render = () => {
        if (this.loggedIn) {
            return <div />;
        } else {
            return <Redirect to={{pathname: "/admin/login"}} />
        }
    }
}
