import {useEffect, useState} from 'react';
import '../styles/CustomerReports.scss';
import Lottie from "lottie-react";

import API from '../util/AdminAPI';
import SelectInput from '../components/SelectInput';
import Checkbox from '../components/Checkbox';
import Modal from '../components/Modal';

import LoadingAnimation from '../assets/loading.json';

const CustomerReports = (props) => {
    const api: API = props.API;

    const [herds, setHerds] = useState([]);
    const [herdId, setHerdId] = useState(props.herdId);
    const [loading, setLoading] = useState(false);
    const [currentName, setCurrentName] = useState("");
    const [paymentVisible, setPaymentVisible] = useState(false);
    const [munRate, setMunRate] = useState("x");

    const report_options = [
        "Favorites",
        "Standard",
        "Summary",
        "Summary with MUN",
        "Energy Corrected Milkweights",
        "Fat Corrected Milkweights",
        "Low Milkweight",
        "Low Milkweight by Pen",
        "Sorted by Milkweight",
        "Sorted by Pen",
        "Sorted by Pen/Milkweight",
        "Sorted by SCC",
        "SCC Contribution",
        "SCC over 500"
    ];

    const report_funcs = [
        api.getFavoriteReports,
        api.getStandardReport,
        api.getSummaryReport,
        () => {setPaymentVisible(true)},
        api.getEnergyCorrectedReport,
        api.getFatCorrectedReport,
        api.getLowMilkweightReport,
        api.getLowMilkweightPenReport,
        api.getByMilkweightReport,
        api.getByPenReport,
        api.getByPenMilkweightReport,
        api.getBySCCReport,
        api.getSCCContributionReport,
        api.getSCCOver500Report
    ];

    const getSummaryMUN = async () => {
        await api.getSummaryMUNReport(herdId);
    }

    const getHerds = async () => {
        let hs = await api.getActiveHerds();
        setHerds(hs.map(h => {
            h.name = h.customer_name + " | " + h.name;
            return h;
        }));
    }

    const getMunRate = async () => {
        const r = await api.getMunRate();
        setMunRate('$' + r.mun_rate.toFixed(2));
    }

    const hideModal = () => {
        setPaymentVisible(false);
    }

    useEffect(() => {
        getHerds();
        getMunRate();
    }, [])

    const selectHerd = (index) => {
        setHerdId(herds[index].id);
    }

    const downloadSelected = async (index) => {
        setLoading(true);
        setCurrentName(report_options[index]);
        await report_funcs[index](herdId);
        setLoading(false);
    }

    const getPref = (index) => {
        const herd = herds.find(h => h.id == herdId);
        return (herd.favorite_reports >> index) & 1;
    }

    const setPref = async (index) => {
        let herdIndex = herds.findIndex(h => h.id == herdId);
        const herd = herds[herdIndex];
        if (getPref(index)) {
            herd.favorite_reports -= Math.pow(2, index);
        } else {
            herd.favorite_reports += Math.pow(2, index);
        }

        let h = herds.slice()
        h[herdIndex] = herd;
        setHerds(h);

        await api.updateHerd(herd.id, {
            favorite_reports: herd.favorite_reports
        });
    }

    return (
        <div className="CustomerReportScreen">
            {api.render()}
            <div style={{display: 'flex'}}>
                <div className="container">
                    <h2>Download Reports</h2>
                    <ol>
                        <li>
                            <SelectInput
                                selectedIndex={herds.findIndex(h => h.id==herdId)}
                                default="Select a Herd"
                                options={herds}
                                onSelect={selectHerd}
                            />
                        </li>
                        <li className={!herdId? "hidden": ""}>
                            <SelectInput
                                selectedIndex="-1"
                                default="Select a Report"
                                options={report_options.map((e,i) => {return {id:i, name:e};})}
                                onSelect={downloadSelected}
                                removeFirst={true}
                            />
                        </li>
                    </ol>
                    <div className={"animation " + (loading? "": "hidden")}>
                        <p style={{whiteSpace: "nowrap"}}>Downloading {currentName} Report</p>
                        <Lottie animationData={LoadingAnimation} style={{width: 100}}/>
                    </div>
                </div>
                {
                    (!(herdId) || herds.length===0)? null:
                <div style={{paddingLeft: 128}}>
                    <h3>Favorite Reports</h3>
                    {
                        report_options.slice(1).map((e, i) => {
                            return <Checkbox key={i} label={e} checked={getPref(i)} setChecked={() => {setPref(i)}}/>
                        })
                    }
                </div>
                }
            </div>
            <Modal
                title="Extra Charge Notice"
                visible={paymentVisible}
                primaryText="Proceed"
                onPrimary={() => {
                    hideModal();
                    getSummaryMUN();
                }}
                secondaryText="Cancel"
                onSecondary={hideModal}
            >
                <p style={{width: 300}}>Downloading MUN data will incur a one time charge of {munRate} per cow. Proceed?</p>
            </Modal>
        </div>
    );
}

export default CustomerReports;
